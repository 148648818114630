import { createGlobalStyle } from "styled-components";
import { black, white } from "./colors";

import AdelleBoldOtf from "../fonts_ral/Adelle_Bold.otf";
import AdelleSemiBoldOtf from "../fonts_ral/Adelle_Semibold.otf";
import AdelleExtraBoldOtf from "../fonts_ral/Adelle_ExtraBold.otf";
import AdelleRegOtf from "../fonts_ral/Adelle_Reg.otf";
import AdelleLightItalicOtf from "../fonts_ral/Adelle_LightItalic.otf";
import AdelleBoldItalicOtf from "../fonts_ral/Adelle_SemiBoldItalic.otf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Conv_Adelle_Bold';
    src: url(${AdelleBoldOtf});
  }
    
  @font-face {
    font-family: 'Conv_Adelle_Semi_Bold';
    src: url(${AdelleSemiBoldOtf});
  }

  @font-face {
    font-family: 'Conv_Adelle_Extra_Bold';
    src: url(${AdelleExtraBoldOtf});
  }
    
  @font-face {
    font-family: 'Conv_Adelle_Reg';
    src: url(${AdelleRegOtf});
  }
    
  @font-face {
    font-family: 'Conv_Adelle_Light_Ita';
    src: url(${AdelleLightItalicOtf});
  }
    
  @font-face {
    font-family: 'Conv_Adelle_Bold_Ita';
    src: url(${AdelleBoldItalicOtf});
  }

  html {
    box-sizing: border-box;
    font-family: 'Conv_Adelle_Reg',Sans-Serif;
    background-color: ${white};
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  a {
    cursor: pointer;
    text-decoration:none;
  }
 
  ul, li, h1, h2, h3, p  {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  button {
    border: 0;
    outline: 0;
  }

  body {
    max-width: 1920px;
    margin: 0 auto;
    background-color: ${white};
    color: ${black};
    overscroll-behavior: none;
    width: 100%;
  }

  #app {
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    overflow-x: hidden;
  }
`;
